<template>
  <b-form-group>
    <template #label>
      <div>
        {{ $t(`promotionAg.${label}`) }}
      </div>
    </template>

    <v-select
      :id="label"
      v-model="inputData"
      style="font-size: 1rem;"
      multiple
      taggable
      :filterable="false"
      :placeholder="$t(placeholder)"
      class="v-select-wrap"
    >
      <template #search="{ attributes, events }">
        <input
          ref="refShowInputCountryTrips"
          v-bind="attributes"
          class="vs__search"
          oninput="this.value = this.value.replaceAll(' ','').toUpperCase()"
          v-on="events"
          @input="handleInput"
          @keydown.enter="handleEnter"
        >
      </template>
      <template #no-options>
        {{ $t('promotionAg.placeholder.selectOrInput') }}
      </template>
    </v-select>
  </b-form-group>
</template>

<script>
import { ref } from '@vue/composition-api'
import { BFormGroup } from 'bootstrap-vue'
import cloneDeep from 'lodash/cloneDeep'
import vSelect from 'vue-select'

export default {
  components: {
    BFormGroup,
    vSelect,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    valueLength: {
      type: Number,
      required: true,
    },
    valueMaxLength: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const inputData = ref([])

    const enterEvent = new KeyboardEvent('keydown', {
      bubbles: true,
      cancelable: true,
      key: 'Enter',
      code: 'Enter',
      keyCode: 13,
      which: 13,
    })

    function handleInput() {
      const inputElement = this.$refs.refShowInputCountryTrips
      let inputValue = inputElement.value.replace(/[^a-zA-Z0-9-]/g, '')

      if (inputValue.length === props.valueLength) {
        inputValue += '-'
      }

      inputElement.value = inputValue

      if (inputValue.length === props.valueMaxLength) {
        this.$nextTick(() => {
          // inputElement.dispatchEvent(new KeyboardEvent('keydown', { key: 'Enter' }))
          inputElement.dispatchEvent(enterEvent)
        })
      }

      inputElement.focus()
    }

    function handleEnter(event) {
      const value = event.target.value.trim()
      if (!value) return

      inputData.value = cloneDeep([...new Set([...inputData.value, value])])
      emit('updateValue', inputData.value)

      this.$nextTick(() => {
        this.$refs.refShowInputCountryTrips.value = ''
        this.$refs.refShowInputCountryTrips.focus()
      })
    }

    return {
      inputData,
      handleInput,
      handleEnter,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.v-select-wrap {
  .vs__selected-options {
    flex-wrap: wrap;
  }
}
</style>
