var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-form-group', {
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('div', [_vm._v(" " + _vm._s(_vm.$t("promotionAg.".concat(_vm.label))) + " ")])];
      },
      proxy: true
    }])
  }, [_c('v-select', {
    staticClass: "v-select-wrap",
    staticStyle: {
      "font-size": "1rem"
    },
    attrs: {
      "id": _vm.label,
      "multiple": "",
      "taggable": "",
      "filterable": false,
      "placeholder": _vm.$t(_vm.placeholder)
    },
    scopedSlots: _vm._u([{
      key: "search",
      fn: function fn(_ref) {
        var attributes = _ref.attributes,
          events = _ref.events;
        return [_c('input', _vm._g(_vm._b({
          ref: "refShowInputCountryTrips",
          staticClass: "vs__search",
          attrs: {
            "oninput": "this.value = this.value.replaceAll(' ','').toUpperCase()"
          },
          on: {
            "input": _vm.handleInput,
            "keydown": function keydown($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
              return _vm.handleEnter($event);
            }
          }
        }, 'input', attributes, false), events))];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('promotionAg.placeholder.selectOrInput')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.inputData,
      callback: function callback($$v) {
        _vm.inputData = $$v;
      },
      expression: "inputData"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }